import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Button } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import "./Header.css";
import logo from "../../assets/images/logocow.png";
import { useTranslation } from "react-i18next";
import {useAuthStore} from "../../context/authStore";

const flagsContext = require.context('../../assets/flags', false, /\.svg$/);

// Create an object to store the SVG paths
const flags = flagsContext.keys().reduce((acc, path) => {
  const key = path.replace('./', '').replace('.svg', '');
  acc[key] = flagsContext(path);
  return acc;
}, {});


const getFlagImagePath = (code) => {
  try {
    return flags[code] || null;
  } catch (error) {
    return null;
  }
};

const languages = [
  { code: "en", lang: "EN", flag: "GBP" },
  { code: "vi", lang: "VN", flag: "VND" },
];

// nav links
const nav__links = [
  {
    path: "/home",
    display: "Home",
  },
];

// header
const Header = () => {
  const headerRef = useRef(null);
  const menuRef = useRef(null);
  const { i18n } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  
  const [user, setUser] = useState(null);
  const { user: authUser, logout } = useAuthStore();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser !== null) {
      setUser(JSON.parse(storedUser));
    } else {
      setUser(authUser);
    }
  }, [authUser]);


  const handleLogout = () => {
    logout();
    setUser(null);
  };

  const stickyHeaderFunc = () => {
    if (headerRef.current) {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        headerRef.current.classList.add("sticky__header");
      } else {
        headerRef.current.classList.remove("sticky__header");
      }
    }
  };

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsDropdownOpen(false);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const getFlag = (language) => {
    switch (language) {
      case 'en':
        return 'GBP';
      case 'vi':
        return 'VND';
      default:
        return 'GBP';
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickyHeaderFunc);

    return () => {
      window.removeEventListener("scroll", stickyHeaderFunc);
    };
  }, []);

  const toggleMenu = () => {
    if (menuRef.current) {
      menuRef.current.classList.toggle("show__menu");
    }
  };

  const { t } = useTranslation();

  return (
    <header className="header" ref={headerRef}>
      <Container>
        <Row>
          <div className="nav__wrapper">
            {/* <!-- ========== Start Logo Section ========== -->*/}

            <div className="logo header-title">
              <Link to="/" className="logo-link">
                <img src={logo} alt="logo" className="logo-image" />
                <span className="logo-text">COW</span>
              </Link>
            </div>

            {/* <!-- ========== End Logo Section ========== --> */}

            {/* <!-- ========== Start Menu Section ========== --> */}

            <div className="navigation" ref={menuRef} onClick={toggleMenu}>
               <ul className="navbar">
              {
                nav__links.map((item, index) => {
                  return (
                  <li className="nav__item" key={index}>
                    <NavLink to={item.path} className= 
                    { navClass => navClass.isActive ? "active__link" : '' }>
                      {item.display}</NavLink>
                  </li>
                  )
                })
              }
            </ul> 
            </div>

            {/* <!-- ========== End Menu Section ========== --> */}

            <div className="information">
              {user ? (
                <>
                  <h5 className="mb-0">{user.name}</h5>
                  <Button
                    className="btn btn-logout"
                    style={{ background: "orange", border: "none" }}
                    onClick={handleLogout}
                  >
                    {t("logout")}
                  </Button>
                </>
              ) : (
                <>
                  <Button className="btn secondary__btn">
                    <Link to="/login">{t("login.title")}</Link>
                  </Button>

                  <Button className="btn primary__btn">
                    <Link to="/register">{t("register.title")}</Link>
                  </Button>
                </>
              )}
            </div>

            {/* Language Selector */}
            <div className="currency-selector">
            <div className="icon" onClick={handleDropdownToggle}>
              {i18n.language && (
                <img
                  src={getFlagImagePath(getFlag(i18n.language))}
                  alt={`${getFlag(i18n.language)} icon`}
                />
              )}
            </div>
            {isDropdownOpen && (
              <div className="dropdown-menu-custom">
                {languages.map((lg) => (
                  <div
                    key={lg.flag}
                    className="dropdown-item-custom"
                    onClick={() => changeLanguage(lg.code)}
                  >
                    <img
                      src={getFlagImagePath(lg.flag)}
                      alt={`${lg.flag} icon`}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>


            {/* Mobile menu */}
            <span className="mobile__menu" onClick={toggleMenu}>
              <i className="ri-menu-line"></i>
            </span>
          </div>
        </Row>
      </Container>
    </header>
  );
};

export default Header;


