import React, { useState } from 'react';
import '../style/login.css';
import { Container, Row, Col, Form,  FormGroup, Button } from 'reactstrap';
import {Link} from "react-router-dom";
import loginImg from '../assets/images/login.png';
import userIcon from '../assets/images/user.png';
import { useAuthStore } from '../context/authStore';
import { useTranslation } from "react-i18next";

// login
const Login = () => {

  const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
  const { login, isLoading, error } = useAuthStore();

  //translation
  const { t } = useTranslation();

  //hanndel login button click
	const handleLogin = async (e) => {
		e.preventDefault();
		await login(email, password);
	};
  

  return<section>
    <Container>
      <Row>
        <Col lg='8' className='m-auto'>
          <div className="login__container d-flex justify-content-between">
            <div className="login__img">
              <img src={loginImg} alt="login" />
            </div>


            <div className="login__form">
              <div className="user">
                <img src={userIcon} alt="n" />
              </div>
              <h2>{t("login.title")}</h2>
              <Form onSubmit={handleLogin}>
                <FormGroup>
                  <input type="email" placeholder={t("login.email")} required id='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                </FormGroup>

                <FormGroup>
                  <input type="password" placeholder={t("login.password")} required id='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                </FormGroup>

                <Link to='/forgot-password' >
                  {t("login.forgot")}
                </Link>
                <Button className='btn secondary__btn auth__btn' type='submit'> {t("login.button")}</Button>
              </Form>
              <p> {t("login.account")} <Link to='/register'> {t("login.create")}</Link></p>
            </div>
          </div>

        </Col>
      </Row>
    </Container>
  </section>
}

export default Login;
