import { create } from "zustand";
import { API_URL } from "../utils/config";


export const useAuthStore = create((set) => ({
    user: null,
    isAuthenticated: false,
    error: null,
    isLoading: false,
    isCheckingAuth: true,
    message: null,

    signup: async (email, password, fullName, confirmPassword) => {
        set({ isLoading: true, error: null });
        try {
            const response = await fetch(`${API_URL}/api/auth/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({ email, password, fullName, confirmPassword })
            });
            const data = await response.json();
            if (!response.ok) throw new Error(data.message || "Error signing up");
            set({ user: data.user, isAuthenticated: true, isLoading: false });
        } catch (error) {
            set({ error: error.message, isLoading: false });
            throw error;
        }
    },
    login: async (email, password) => {
        set({ isLoading: true, error: null });
        try {
            const response = await fetch(`${API_URL}/api/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({ email, password })
            });
            const data = await response.json();
            if (!response.ok) throw new Error(data.message || "Error logging in");
            set({
                isAuthenticated: true,
                user: data.user,
                error: null,
                isLoading: false,
            });
            localStorage.setItem('user', JSON.stringify(data.user));
            localStorage.setItem('isAuthenticated', true);
        } catch (error) {
            set({ error: error.message, isLoading: false });
            throw error;
        }
    },

    logout: async () => {
        set({ isLoading: true, error: null });
        try {
            const response = await fetch(`${API_URL}/api/auth/logout`, {
                method: 'POST',
                credentials: 'include'
            });
            if (!response.ok) throw new Error("Error logging out");
            set({ user: null, isAuthenticated: false, error: null, isLoading: false });
            localStorage.removeItem('user');
            localStorage.removeItem('isAuthenticated');
        } catch (error) {
            set({ error: error.message, isLoading: false });
            throw error;
        }
    },
    verifyEmail: async (code) => {
        set({ isLoading: true, error: null });
        try {
            const response = await fetch(`${API_URL}/api/auth/verify-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({ code })
            });
            const data = await response.json();
            if (!response.ok) throw new Error(data.message || "Error verifying email");
            set({ user: data.user, isAuthenticated: true, isLoading: false });
            localStorage.setItem('user', JSON.stringify(data.user));
            localStorage.setItem('isAuthenticated', true);
            return data;
        } catch (error) {
            set({ error: error.message, isLoading: false });
            throw error;
        }
    },
    checkAuth: async () => {
        set({ isCheckingAuth: true, error: null });
        try {
            const response = await fetch(`${API_URL}/api/auth/check-auth`, {
                method: 'GET',
                credentials: 'include'
            });
            const data = await response.json();
            if (!response.ok) throw new Error("Error checking auth");
            set({ user: data.user, isAuthenticated: true, isCheckingAuth: false });
        } catch (error) {
            set({ error: null, isCheckingAuth: false, isAuthenticated: false });
        }
    },
    forgotPassword: async (email) => {
        set({ isLoading: true, error: null });
        try {
            const response = await fetch(`${API_URL}/api/auth/forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({ email })
            });
            const data = await response.json();
            if (!response.ok) throw new Error(data.message || "Error sending reset password email");
            set({ message: data.message, isLoading: false });
        } catch (error) {
            set({
                isLoading: false,
                error: error.message,
            });
            throw error;
        }
    },
    resetPassword: async (token, password) => {
        set({ isLoading: true, error: null });
        try {
            const response = await fetch(`${API_URL}/api/auth/reset-password/${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({ password })
            });
            const data = await response.json();
            if (!response.ok) throw new Error(data.message || "Error resetting password");
            set({ message: data.message, isLoading: false });
        } catch (error) {
            set({
                isLoading: false,
                error: error.message,
            });
            throw error;
        }
    },
}));