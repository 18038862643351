import React from "react";
import { Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Home from "../pages/Home";
import Register from "../pages/Register";
import Login from "../pages/Login";
import Chart from "../pages/Chart";
import EmailVerification from "../pages/EmailVerification";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import { useAuthStore } from "../context/authStore";

// protect routes that require authentication
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, user } = useAuthStore();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (!user.isVerified) {
    return <Navigate to="/verify-email" replace />;
  }

  return children;
};

// redirect authenticated users to the home page
const RedirectAuthenticatedUser = ({ children }) => {
  const { isAuthenticated, user } = useAuthStore();

  if (isAuthenticated && user.isVerified) {
    return <Navigate to="/" replace />;
  }

  return children;
};

// routes
const Router = () => {
  // const { isCheckingAuth, checkAuth } = useAuthStore();

  // useEffect(() => {
  //   checkAuth();
  // }, [checkAuth]);

  // if (!isCheckingAuth) return null;

  return (
    //  <Routes basename="/">

    //   <Route path='/' element={<Home/>} />
    //   <Route path='/home' element={<Chart/>} />
    //   <Route path='/login' element={<Login/>} />
    //   <Route path='/register' element={<Register/>} />
    //   <Route path='/verify-email' element={<EmailVerification/>} />
    //   <Route path='/forgot-password' element={<ForgotPassword/>} />
    //   <Route path='/reset-password/:token' element={<ResetPassword/>} />

    //  </Routes>

    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Chart />} />
      <Route
        path="/register"
        element={
          <RedirectAuthenticatedUser>
            <Register />
          </RedirectAuthenticatedUser>
        }
      />
      <Route
        path="/login"
        element={
          <RedirectAuthenticatedUser>
            <Login />
          </RedirectAuthenticatedUser>
        }
      />
      <Route path="/verify-email" element={<EmailVerification />} />
      <Route
        path="/forgot-password"
        element={
          <RedirectAuthenticatedUser>
            <ForgotPassword />
          </RedirectAuthenticatedUser>
        }
      />

      <Route
        path="/reset-password/:token"
        element={
          <RedirectAuthenticatedUser>
            <ResetPassword />
          </RedirectAuthenticatedUser>
        }
      />
      {/* catch all routes */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default Router;
