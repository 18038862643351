import React, { useEffect, useRef, useState, useCallback } from "react";
import "../style/login.css";
import { Container, Row, Col, Form, FormGroup, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import regImg from "../assets/images/register.png";
import userIcon from "../assets/images/user.png";
import { useAuthStore } from "../context/authStore";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const EmailVerification = () => {
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  //translation
  const { t } = useTranslation();
  
  //auth store verify email
  const { error, isLoading, verifyEmail } = useAuthStore();

  //input digit change
  const handleChange = (index, value) => {
    const newCode = [...code];

    // Handle pasted content
    if (value.length > 1) {
      const pastedCode = value.slice(0, 6).split("");
      for (let i = 0; i < 6; i++) {
        newCode[i] = pastedCode[i] || "";
      }
      setCode(newCode);

      // Focus on the last non-empty input or the first empty one
      const lastFilledIndex = newCode.findLastIndex((digit) => digit !== "");
      const focusIndex = lastFilledIndex < 5 ? lastFilledIndex + 1 : 5;
      inputRefs.current[focusIndex].focus();
    } else {
      newCode[index] = value;
      setCode(newCode);

      // Move focus to the next input field if value is entered
      if (value && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  // Move focus to the previous input field on backspace
  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !code[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const verificationCode = code.join("");
    try {
      await verifyEmail(verificationCode);
      navigate("/");
      toast.success(t("email_verification.success"));
    } catch (error) {
      console.log(error);
    }
  }, [code, navigate, verifyEmail, t]);

  // Auto submit when all fields are filled
  useEffect(() => {
    if (code.every((digit) => digit !== "")) {
      handleSubmit(new Event("submit"));
    }
  }, [code, handleSubmit]);

  return (
    <section>
      <Container>
        <Row>
          <Col lg="8" className="m-auto">
            <div className="login__container d-flex justify-content-between">
              <div className="login__img">
                <img src={regImg} alt="login" />
              </div>

              <div className="login__form">
                <div className="user">
                  <img src={userIcon} alt="n" />
                </div>
                <br />
                <h2>{t("email_verification.title")}</h2>
                <p>{t("email_verification.note")}</p>

                <Form onSubmit={handleSubmit}>
                  <FormGroup className="form__digit">
                    {code.map((digit, index) => (
                      <input
                        key={index}
                        ref={(el) => (inputRefs.current[index] = el)}
                        type="text"
                        maxLength="6"
                        value={digit}
                        onChange={(e) => handleChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                      />
                    ))}
                  </FormGroup>
                  {error && (
                    <p className="text-red-500 font-semibold mt-2">{error}</p>
                  )}
                  <Button
                    className="btn secondary__btn auth__btn"
                    type="submit"
                    disabled={isLoading || code.some((digit) => !digit)}
                  >
                    {isLoading ? "..." : t("email_verification.button")}
                  </Button>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default EmailVerification;