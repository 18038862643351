import React, { useState } from "react";
import { useAuthStore } from "../context/authStore";
import "../style/login.css";
import { Link} from "react-router-dom";
import { Container, Row, Col, Form, FormGroup, Button } from "reactstrap";
import regImg from "../assets/images/login.png";
import userIcon from "../assets/images/user.png";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  //translation
  const { t } = useTranslation();

  //auth store forgot password
  const { isLoading, forgotPassword } = useAuthStore();

  //handle submit forgot password click
  const handleSubmit = async (e) => {
    e.preventDefault();
    await forgotPassword(email);
    setIsSubmitted(true);
  };

  return (
    <section>
      <Container>
        <Row>
          <Col lg="8" className="m-auto">
            <div className="login__container d-flex justify-content-between">
              <div className="login__img">
                <img src={regImg} alt="login" />
              </div>

              <div className="login__form">
                <div className="user">
                  <img src={userIcon} alt="n" />
                </div>
                <br />
                <h2>{t("forget_password.title")}</h2>
                {!isSubmitted ? (
                  <Form onSubmit={handleSubmit}>
                    <p>
                      {t("forget_password.note")}
                    </p>
                    <FormGroup>
                      <input
                        type="email"
                        placeholder={t("forget_password.email")}
                        required
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormGroup>

                    <Button
                      className="btn secondary__btn auth__btn"
                      type="submit"
                    >
                      {isLoading ? "..." : t("forget_password.button")}
                    </Button>
                  </Form>
                ) : (
                  <p>
                      {t("forget_password.success", { email: email })}
                  </p>
                )}
                <div>
                  <Link to={"/login"} className="back-to-login-button">
                    {t("forget_password.back")}
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default ForgotPassword;
