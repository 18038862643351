import { configureStore } from '@reduxjs/toolkit';
import userReducer from "./reduces/userSlice"

import { trendingApi } from '../hooks/trendingApi'

export const store = configureStore({
    reducer: {
        user: userReducer,
        // Add the generated reducer as a specific top-level slice
        [trendingApi.reducerPath]: trendingApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(trendingApi.middleware),
})

