import React, { useState } from "react";
import { useAuthStore } from "../context/authStore";
import "../style/login.css";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Form, FormGroup, Button } from "reactstrap";
import regImg from "../assets/images/login.png";
import userIcon from "../assets/images/user.png";
import toast from "react-hot-toast";
import PasswordStrengthMeter from "../shared/PasswordStrengthMeter";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { resetPassword, error, isLoading, message } = useAuthStore();

  //translation
  const { t } = useTranslation();
  
  //get token from url
  const { token } = useParams();
  const navigate = useNavigate();

  //handle submit forgot password click
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert(t("reset_password.alert"));
      return;
    }
    try {
      await resetPassword(token, password);

      toast.success(
          t("reset_password.success")
      );
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      console.error(error);
      toast.error(error.message || "Error resetting password");
    }
  };

  return (
    <section>
      <Container>
        <Row>
          <Col lg="8" className="m-auto">
            <div className="login__container d-flex justify-content-between">
              <div className="login__img">
                <img src={regImg} alt="login" />
              </div>

              <div className="login__form">
                <div className="user">
                  <img src={userIcon} alt="n" />
                </div>
                <br />
                <h2>{t("reset_password.title")}</h2>

                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <input
                      type="password"
                      placeholder={t("reset_password.password")}
                      required
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </FormGroup>

                  <FormGroup>
                    <input
                      type="password"
                      placeholder={t("reset_password.confirm_password")}
                      required
                      id="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </FormGroup>

                  <PasswordStrengthMeter password={password} />

                  <Button
                    className="btn secondary__btn auth__btn"
                    type="submit"
                  >
                    {isLoading ? "..." : t("reset_password.button")}
                  </Button>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default ForgotPassword;
