import React, { useState, useEffect } from "react";
import "./Card.css";
import { Sparklines, SparklinesLine } from "react-sparklines";

import useLazyLoad from "../../hooks/useLazyLoad";

const Card = ({ data, onClick }) => {
  const [isVisible, ref] = useLazyLoad();

  const [currencyPrice, setCurrencyPrice] = useState([]);

  useEffect(() => {
    const rateData = data?.rate || {};
    const timestamps = Object.keys(rateData);
    const newCurrencyPrice = [];

    timestamps.forEach((timestamp) => {
      newCurrencyPrice.push(rateData[timestamp]);
    });

    setCurrencyPrice(newCurrencyPrice);
  }, [data]);

  return (
    <>
      <div ref={ref} className="cardDetail" onClick={onClick}>
        {isVisible ? (
          <div className="cardDetail__columns">
            {/* value name detail */}
            <div className="cardDetail__currency">
              <span className="currency-name">{data.symbol.toUpperCase()}</span>
              <span className="currency-code">{data.to.code}</span>
            </div>
            {/* rate chart 24h */}
            {isVisible && (
              <svg
                className="card__chart"
                width="65%"
                height="100%"
                viewBox="0 0 120 40"
                style={{ marginLeft: "5%", marginTop: "2%" }}
              >
                <Sparklines data={currencyPrice.slice(0, currencyPrice.length)}>
                  <SparklinesLine color={data?.change < 0 ? "red" : "green"} />
                </Sparklines>
              </svg>
            )}
            {/* rateEnd and percentChange 24h */}
            <div className="cardDetail__change">
              <span className="cardDetail__change--price">
                {data.endRate.toFixed(2)}
              </span>
              <span className={`cardDetail__change--valuechange`}>
                {data?.change
                  ? (() => {
                      const color = data.change < 0 ? "red" : "green";
                      const sign = data.change > 0 ? "+" : "-";
                      return (
                        <span style={{ color }}>
                          {sign} {Math.abs(data.change).toFixed(2)} %
                        </span>
                      );
                    })()
                  : "N/A"}
              </span>
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </>
  );
};

export default Card;
