import React, { useState } from 'react';
import '../style/login.css';
import { Container, Row, Col, Form,  FormGroup, Button } from 'reactstrap';
import {Link, useNavigate} from "react-router-dom";
import regImg from '../assets/images/register.png';
import userIcon from '../assets/images/user.png';
import  PasswordStrengthMeter from '../shared/PasswordStrengthMeter';
import { useAuthStore } from '../context/authStore';
import { useTranslation } from 'react-i18next';

const Register = () => {

  const [fullName, setFullName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const navigate = useNavigate();

  //translation
  const { t } = useTranslation();

  //auth register
	const { signup, error, isLoading } = useAuthStore();

  //handle register button click
	const handleRegister = async (e) => {
		e.preventDefault();

		try {
			await signup(email, password, fullName, confirmPassword);
			navigate("/verify-email");
		} catch (error) {
			console.log(error);
		}
	};

  return<section>
    <Container>
      <Row>
        <Col lg='8' className='m-auto'>
          <div className="login__container d-flex justify-content-between">
            <div className="login__img">
              <img src={regImg} alt="login" />
            </div>


            <div className="login__form">
              <div className="user">
                <img src={userIcon} alt="n" />
              </div>
              <h2>{t("register.title")}</h2>
              <Form onSubmit={handleRegister}>

              <FormGroup>
                  <input type="text" placeholder={t("register.fullname")} required id='fullName' value={fullName} onChange={(e) => setFullName(e.target.value)} />
                </FormGroup>


                <FormGroup>
                  <input type="email" placeholder={t("register.email")} required id='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                </FormGroup>

                <FormGroup>
                  <input type="password" placeholder={t("register.password")} required id='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                </FormGroup>

                <FormGroup>
                  <input type="password" placeholder={t("register.confirm_password")} required id='confirmPassword' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                </FormGroup>
                
                <PasswordStrengthMeter password={password} /> 

                <Button className='btn secondary__btn auth__btn' type='submit'>{t("register.login")}</Button>
              </Form>
              <p>{t("register.account")}  <Link to='/login'>{t("register.login")}</Link></p>
            </div>
          </div>

        </Col>
      </Row>
    </Container>
  </section>
}

export default Register;