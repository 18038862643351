import React from "react";
import "./footer.css";
import { Container, Row,} from "reactstrap";

// add quick link
// const quick__links = [
//   {
//     path: "/home",
//     display: "Home",
//   },
//   {
//     path: "/about",
//     display: "About",
//   },
//   {
//     path: "/tours",
//     display: "Tours",
//   },
// ];

// qucik link 2
// const quick__links2 = [
//   {
//     path: "/gallery",
//     display: "Gallery",
//   },
//   {
//     path: "/login",
//     display: "Login",
//   },
//   {
//     path: "/register",
//     display: "Register",
//   },
// ];

// footer

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="footer">
      <Container>
        <Row>
          {/* <Col lg='3'>
          <div className="logo">
            <img src={logo} alt="logo" />
            <p> a copyright notice, link to a privacy policy, sitemap, logo, contact information, social media icons, and an email sign-up form.</p>


            <div className="social__links d-flex align-items-center gap-4">
              <span>
                <Link to="#"><i class="ri-youtube-fill"></i></Link>
              </span>

              <span>
                <Link to="#"><i class="ri-facebook-fill"></i></Link>
              </span>

              <span>
                <Link to="#"><i class="ri-instagram-line"></i></Link>
              </span>

              <span>
                <Link to="#"><i class="ri-twitter-fill"></i></Link>
              </span>

              <span>
                <Link to="#"><i class="ri-mail-line"></i></Link>
              </span>
            </div>
          </div>
        </Col>
        <Col lg='3'>
          <h5 className="footer__link-title">Discover</h5>

          <ListGroup className='footer__quick-links'>
            {
              quick__links.map((item, index) => (
                <ListGroupItem key={index} className='ps-0 border-0'>
                  <Link to={item.path}>{item.display}</Link>
                </ListGroupItem>
              ))
            }
          </ListGroup>
        </Col>
        <Col lg='3'>
          <h5 className="footer__link-title">Quick Links</h5>

          <ListGroup className='footer__quick-links'>
            {
              quick__links2.map((item, index) => (
                <ListGroupItem key={index} className='ps-0 border-0'>
                  <Link to={item.path}>{item.display}</Link>
                </ListGroupItem>
              ))
            }
          </ListGroup>
        </Col>
        <Col lg='3'>
        <h5 className="footer__link-title">Contact</h5>

<ListGroup className='footer__quick-links'>
  
    
      <ListGroupItem  className='ps-0 border-0 d-flex align-items-center gap-3'>
       <h6 className='mb-0 d-flex align-items-center gap-2'>
        <span><i class="ri-map-pin-line"></i></span>
        Address:
       </h6>

       <p className='mb-0'>COW</p>
      </ListGroupItem>


      <ListGroupItem  className='ps-0 border-0 d-flex align-items-center gap-3'>
       <h6 className='mb-0 d-flex align-items-center gap-2'>
        <span><i class="ri-mail-line"></i></span>
        Email:
       </h6>

       <p className='mb-0'>cow@gmail.com</p>
      </ListGroupItem>


      <ListGroupItem  className='ps-0 border-0 d-flex align-items-center gap-3'>
       <h6 className='mb-0 d-flex align-items-center gap-2'>
        <span><i class="ri-phone-fill"></i></span>
        Phone:
       </h6>

       <p className='mb-0'>+84 123456789</p>
      </ListGroupItem>
    
  
</ListGroup>
        </Col> */}
          <div className="text-center">
            <p className="copyright">
              {" "}
              &copy; Copyright {year}, design and developed by Cow. All rights
              reserved.
            </p>
          </div>
        </Row>
      </Container>
    </footer>
  );
};

// export default
export default Footer;
